import {
  ArchiveBoxIcon,
  BanknotesIcon,
  BuildingStorefrontIcon,
  CalendarIcon,
  ChartBarIcon,
  ListBulletIcon,
  PrinterIcon,
  ScaleIcon,
  ShoppingCartIcon,
  TruckIcon,
} from "@heroicons/vue/24/outline/index";

export const useMenu = () => {
  return [
    {
      id: "tables",
      text: "Tables",
      icon: BuildingStorefrontIcon,
      to: "/tables",
      match: null,
    },
    {
      id: "takeaway",
      text: "Takeaway",
      icon: TruckIcon,
      to: "/takeaway-orders",
      match: null,
    },
    {
      id: "reservations",
      text: "Reservations",
      icon: CalendarIcon,
      to: "/reservations",
      match: null,
    },
    {
      id: "checkout",
      text: "Checkout",
      icon: ShoppingCartIcon,
      to: "/orders/create",
      match: null,
    },
    {
      id: "orders",
      text: "Orders",
      icon: ListBulletIcon,
      to: "/orders",
      match: (path) => path !== "/orders/create" && path.startsWith("/orders"),
    },
    {
      id: "stock",
      text: "Stock",
      icon: ArchiveBoxIcon,
      to: "/stock-statuses",
      match: null,
    },
    {
      id: "print-jobs",
      text: "Print Jobs",
      icon: PrinterIcon,
      to: "/print-jobs",
      match: null,
    },
    {
      id: "settle",
      text: "Settlement",
      icon: ScaleIcon,
      to: "/settlement",
      match: null,
    },
  ];
};
